/**
 * Access `navigator.connection` if supported, otherwise return null
 */
export const getNavigationConnectionProperty = (): NetworkInformation | null =>
  "connection" in navigator ? navigator.connection : null;

declare global {
  interface Navigator {
    readonly connection: NetworkInformation;
  }

  /**
   *
   *
   * @link https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation
   */
  interface NetworkInformation {
    // This interface is missing the properties, that have not yet been used.
    /**
     * Returns true, if the user has set a reduced data usage option on their user agent.
     *
     * @link https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/saveData
     */
    readonly saveData: boolean;
  }
}
